import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { calcolaMutuo } from "./utils"

const Home = () => {
  const [capitale, setCapitale] = useState(320000)
  const [tasso, setTasso] = useState(5.6)
  const [rateAnnue, setRateAnnue] = useState(12)
  const [anni, setAnni] = useState(30)

  const [result, setResult] = useState(0)

  useEffect(() => setResult(calcolaMutuo(capitale, tasso / 100, rateAnnue, anni)), [capitale, tasso, rateAnnue, anni])

  return (
    <div className="total-cont">
      <form onSubmit={e => e.preventDefault()}>
        <div className="input-wrap">
          <label htmlFor="capitale">Capitale</label>
          <input type="number" name="capitale" placeholder="Capitale" onChange={e => setCapitale(e.target.value)} value={capitale} inputMode="numeric" step="1000" />
          <span className="formatted-value">
            {new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR"
            }).format(capitale)}
          </span>
        </div>

        <div className="input-wrap">
          <label htmlFor="tasso">Tasso</label>
          <input type="number" name="tasso" placeholder="Tasso" onChange={e => setTasso(e.target.value)} value={tasso} inputMode="numeric" step="0.1" />
          <span className="formatted-value">{tasso}%</span>
        </div>

        <div className="input-wrap">
          <label htmlFor="rateAnnue">Rate annue</label>
          <input type="number" name="rateAnnue" placeholder="Rate annue" onChange={e => setRateAnnue(e.target.value)} value={rateAnnue} inputMode="numeric" step="1" />
          <span className="formatted-value">{rateAnnue} rate / anno</span>
        </div>

        <div className="input-wrap">
          <label htmlFor="anni">Anni</label>
          <input type="number" name="anni" placeholder="Anni" onChange={e => setAnni(e.target.value)} value={anni} inputMode="numeric" step="5" />
          <span className="formatted-value">{anni} anni</span>
        </div>
      </form>

      <div id="result">
        {Boolean(result && result > 0) && (
          <>
            <p className="rata">
              Rata del mutuo:{" "}
              <span id="value">
                {new Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR"
                }).format(result)}{" "}
                / mese
              </span>
            </p>

            <p className="totale">
              Totale da restituire:{" "}
              <span id="value">
                {new Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR"
                }).format(result * rateAnnue * anni)}{" "}
                / {rateAnnue * anni} rate
              </span>
            </p>

            <p className="interessi">
              Interessi:{" "}
              <span id="value">
                {new Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR"
                }).format(result * rateAnnue * anni - capitale)}{" "}
                ({(((result * rateAnnue * anni - capitale) / capitale) * 100).toFixed(2)} % del capitale)
              </span>
            </p>
          </>
        )}
      </div>
    </div>
  )
}

function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.getElementById("app"))
root.render(<Main />)

if (module.hot) module.hot.accept()
